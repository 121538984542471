<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
      >{{ text }}</span>
    </template>
    <span>{{ tip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: String,
    tip: String,
  }
}
</script>