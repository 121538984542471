<template>
  <v-dialog
    v-model="dialog"
    :width="large ? 700 : 425"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="info"
        v-bind="attrs"
        v-on="on"
        small
        text
      >Ver más</v-btn>
    </template>
    <v-card>
      <v-card-title style="word-break: normal">{{ title }}</v-card-title>
      <v-img
        v-if="large"
        :src="img"
        height="250px"
        contain
      ></v-img>
      <img
        v-else
        :src="img"
      />
      <v-card-text class="mt-6 body-1">
        <p>Sintaxis:</p>
        <code v-html="sintax"></code>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          small
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    title: String,
    img: String,
    sintax: String,
    large: Boolean,
  }
}
</script>