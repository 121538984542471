<template>
  <div>
    <v-row class="d-flex justify-center mx-1 mt-md-4 mt-1">
      <v-col cols="12" sm="10" xl="8">
        <v-row>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="3">
            <Index :initiallyOpen="['Lenguaje', 'Primeros pasos', 'Pini', 'Rosy', 'Configurar pines']" />
          </v-col>
          <v-col cols="12" md="9" class="text-left">

            <h1>Primeros pasos</h1>
            <p>
              Para programar en Rosy IDE se necesitan <strong>dos archivos</strong>. Uno de extensión .rosy que contenga la
              <strong>lógica del programa</strong>, y otro de extensión .pini que almacene la
              <strong>configuración de los pines</strong> correspondientes a esa lógica.
            </p>
            <p>
              Cuando se compila un archivo, Rosy IDE genera un código fuente a partir del archivo Rosy y Pini que se encuentran abiertos en ese momento
              en el editor de texto. Por ello, es necesario tener abiertos ambos archivos a la hora de compilar.
            </p>
            <a href="#buenas-practicas" class="index" @click="$vuetify.goTo('#buenas-practicas')">
              <h2 id="buenas-practicas" class="pt-2"><v-icon left class="pb-2" color="deep-purple"> fas fa-clipboard-check</v-icon>Buenas prácticas</h2>
            </a>
            <p>
              Rosy cuenta con estándares y reglas que hacen a las buenas prácticas
              (si bien no es obligatorio, se recomienda aplicarlas). Las mismas son:
            </p>
            <p>
              <span class="mr-2">•</span> Los nombres de las <strong>variables</strong> se escriben en <code>snake_case</code>
            </p>
            <p>
              <span class="mr-2">•</span> Los nombres de las <strong>constantes</strong> se escriben con <code>camelCase</code>
            </p>
            <p>
              <span class="mr-2">•</span> Los nombres de las <strong>funciones y métodos</strong> se escriben respetando <code>PacalCase</code>
            </p>
            <p>
              <span class="mr-2">•</span> El nombre del <strong>archivo Pini</strong> se debe escribir en <code>kebab-case</code>
            </p>
            <p>
              <span class="mr-2">•</span> La primer parte del nombre del <strong>archivo Rosy</strong> debe ser igual que el nombre del archivo Pini,
              seguido de una o varias palabras que den cuenta de la lógica del programa
            </p>
            <p>
              <span class="text-decoration-underline">Ejemplo</span>: si un archivo Pini llamado <code>robot-1.pini</code> almacena los pines de un robot
              y dos archivos Rosy correspondientes a esa configuración (uno para que el robot siga una línea negra y otro
              para gire en circulos), los archivos deberán llamarse: <code>robot-1-linea-negra.rosy</code> y  <code>robot-1-girar.rosy</code> respectivamente.
            </p>

            <a href="#pini" class="index" @click="$vuetify.goTo('#pini')">
              <h1 id="pini" class="pt-2">Pini</h1>
            </a>
            <a href="#configurar-pines" class="index" @click="$vuetify.goTo('#configurar-pines')">
              <h2 id="configurar-pines" class="pt-2"><v-icon left class="pb-2" color="teal"> fas fa-tools</v-icon>Configurar pines</h2>
            </a>
            <p>
              Los pines de la placa pueden ser configurados de dos formas. La primera, se realiza mediante el uso de la interfaz gráfica y la segunda,
              consiste en escribir el código manualmente.
            </p>
            <h3><v-icon left small class="pb-1" color="blue">fas fa-window-maximize</v-icon>Con interfaz gráfica</h3>
            <p>
              Para acceder a la pantalla de selección de componentes haga click en el botón <v-icon color="black" class="mb-1 mx-1">fas fa-plus</v-icon>
              ubicado en la esquina superior derecha del IDE, o bien, desde el menu
              <code><span class="text-decoration-underline">P</span>ines > Añadir Componente...</code>
            </p>
            <p>
              En la pantalla de selección podrá elegir el sensor o actuador que desee haciendo click sobre el mismo. Se abrirá
              una ventana para que modifique el nombre del componente y seleccione el pin correspondiente.
            </p>
            <h3><v-icon left small class="pb-1" color="orange">fas fa-code</v-icon>Mediante código</h3>
            <p>
              Para configurar un sensor o actuador mediante código deberá escribir la sintaxis correspondiente en el cuadro de texto
              ubicado a la derecha del IDE.
            </p>
            <a href="#sensores-y-actuadores" class="index" @click="$vuetify.goTo('#sensores-y-actuadores')">
              <h2 id="sensores-y-actuadores" class="pt-2"><v-icon left class="pb-2" color="brown"> fas fa-microchip</v-icon>Sensores y actuadores</h2>
            </a>
            <p>
              La interfaz gráfica de Pini cuenta con los siguientes sensores y actuadores:
            </p>
            <v-row class="mb-4">
              <v-col v-for="({ img, title, sintax, large }, index) in items" :key="index" cols="12" md="4">
                <v-hover v-slot="{ hover }">
                  <v-card
                    :elevation="hover ? 12 : 2"
                    :style="hover ? 'cursor: pointer' : ''"
                  >
                    <div class="a-2">
                      <v-img
                        :src="img"
                        :height="large ? '200px' : '150px'"
                        class="grey lighten-3"
                        contain
                      ></v-img>
                    </div>
                    <v-expand-transition>
                      <div v-show="hover">
                        <v-card-title class="pb-0" style="word-break: normal">{{ title }}</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <Componente :title="title" :img="img" :sintax="sintax" :large="large" />
                        </v-card-actions>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>

            <a href="#rosy" class="index" @click="$vuetify.goTo('#rosy')">
              <h1 id="rosy" class="pt-2">Rosy</h1>
            </a>
            <a href="#variables" class="index" @click="$vuetify.goTo('#variables')">
              <h2 id="variables" class="pt-2"><v-icon left class="pb-2" color="green"> fas fa-terminal</v-icon>Variables</h2>
            </a>
            <p>
              El lenguaje permite establecer variables <strong>globales</strong> y <strong>locales</strong>. Ambas pueden ser <strong>constantes</strong>,
              es decir, que su valor no se puede modificar durante el tiempo de ejecución del programa.
            </p>
            <p>
              Para <strong>definir una variable</strong> se debe indicar el <a @click="$vuetify.goTo('#tipos-de-datos')">tipo de dato</a>
              correspondiente y, en caso de ser <strong>global</strong>, deberá declararse al inicio del código. Sintaxis:
            </p>
            <p class="text-center">
              <code><span class="naranja--text">GLOBAL</span> <span class="font-italic"><code>tipo_dato</code></span> variable_1 = valor</code>
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">GLOBAL CONST</span> <span class="font-italic"><code>tipo_dato</code></span> variableConstante1 = valor</code>
            </p>
            <p>
              Las variables <strong>locales</strong> se definen dentro de <a @click="$vuetify.goTo('#funciones-y-metodos')">funciones y/o métodos</a>
              o bien en la lógica del programa. Sintaxis:
            </p>
            <p class="text-center">
              <code><span class="font-italic"><code>tipo_dato</code></span> variable_2 = valor</code>
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">CONST</span> <span class="font-italic"><code>tipo_dato</code></span> variableConstante2 = valor</code>
            </p>
            <a href="#tipos-de-datos" class="index" @click="$vuetify.goTo('#tipos-de-datos')">
              <h2 id="tipos-de-datos" class="pt-2"><v-icon left class="pb-2" color="amber"> fas fa-database</v-icon>Tipos de datos</h2>
            </a>
            <p>
              Rosy admite los siguientes tipos de datos:
            </p>
            <h3>Boolean</h3>
            <p>
              Puede almacenar <strong>dos valores</strong> posibles: <code>TRUE</code> (verdadero) o <code>FALSE</code> (falso). Ejemplo:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">BOOLEAN</span> nombre_variable = <span class="naranja--text">TRUE</span></code>
            </p>
            <h3>Byte</h3>
            <p>
              Almacena un número sin signo de 8 bits, es decir, de 0 a 255. Ejemplo:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">BYTE</span> nombre_variable = 16</code>
            </p>
            <h3>Char</h3>
            <p>
              Almacena el valor de <strong>un caracter</strong> definido entre comillas simples <code>'</code>. Ejemplo:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">CHAR</span> nombre_variable = <span class="verde--text">'a'</span></code>
            </p>
            <h3>Float</h3>
            <p>
              El tipo de dato de <strong>Punto Flotante</strong> almacena el valor de un número con punto decimal de 6 a 7 dígitos de precisión
              (eso significa el número total de dígitos, no el número a la derecha del punto decimal). Ejemplo:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">FLOAT</span> nombre_variable = 0.5</code>
            </p>
            <h3>Int</h3>
            <p>
              El tipo de dato <strong>Entero</strong> almacena un valor numérico de 16 bits, es decir, de -32.768 a 32.767. Ejemplo:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">INT</span> nombre_variable = 407</code>
            </p>
            <h3>Long</h3>
            <p>
              Almacena un valor numérico de 32 bits, es decir, de -2.147.483.648 a 2.147.483.647. Ejemplo:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">LONG</span> nombre_variable = 32769</code>
            </p>
            <h3>String</h3>
            <p>
              Una Cadena representa una <strong>secuencia de caracteres</strong> definida entre comillas dobles <code>"</code>. Ejemplo:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">STRING</span> nombre_variable = <span class="verde--text">"Cadena de caracteres"</span></code>
            </p>
            <a href="#ciclos" class="index" @click="$vuetify.goTo('#ciclos')">
              <h2 id="ciclos" class="pt-2"><v-icon left class="pb-2" color="deep-orange"> fas fa-retweet</v-icon>Ciclos</h2>
            </a>
            <p>
              La mayoria de los ciclos de Rosy trabaja con <strong>condiciones lógicas</strong> del tipo <code>var1 operador_relacional var2</code> donde
              <strong>var1</strong> y <strong>var2</strong> corresponden a dos variables del mismo tipo de dato y
              <strong>operador_relacional</strong> a los siguientes operadores relacionales:
            </p>
            <v-row class="mb-2">
              <v-col>
                <p>
                  <span class="mr-1 mr-md-2">•</span> <code class="mr-2 mr-md-4">==</code>Igual
                </p>
                <p>
                  <span class="mr-1 mr-md-2">•</span> <code class="mr-4 mr-md-6"><span v-text="`>`"></span></code>Mayor
                </p>
                <p>
                  <span class="mr-1 mr-md-2">•</span> <code class="mr-4 mr-md-6"><span v-text="`<`"></span> </code>Menor
                </p>
              </v-col>
              <v-col>
                <p>
                  <span class="mr-1 mr-md-2">•</span> <code class="mr-2 mr-md-4"><span v-text="`<>`"></span></code>Distinto
                </p>
                <p>
                  <span class="mr-1 mr-md-2">•</span> <code class="mr-2 mr-md-4"><span v-text="`>=`"></span></code>Mayor o igual
                </p>
                <p>
                  <span class="mr-1 mr-md-2">•</span> <code class="mr-2 mr-md-4"><span v-text="`<=`"></span></code>Menor o igual
                </p>
              </v-col>
            </v-row>
            <p>
              Además, el lenguaje cuenta con los <strong>operadores lógicos</strong> <code class="naranja--text">AND</code> (<strong>Y</strong> lógico)
              <code class="naranja--text">OR</code> (<strong>O</strong> lógico) y <code class="naranja--text">NOT</code> (negación) que permiten
              definir <strong>condiciones lógicas</strong> complejas.
            </p>
            <h3>If</h3>
            <p>
              La sentencia <code class="naranja--text">IF</code> valida una <strong>condición lógica</strong> y, en caso de ser verdadera, ejecuta el código
              contenido entre las palabras claves <code class="naranja--text">THEN</code> y <code class="naranja--text">END</code>. Sintaxis:
            </p>
            <p class="text-center">
              <img src="../../src/assets/examples/if.svg" />
            </p>
            <h3>Elif / Else</h3>
            <p>
              Estas sentencias solo pueden ser utilizadas luego de un bloque <code class="naranja--text">IF</code>, lo que permite un <strong>mayor control</strong>
              sobre el flujo del código. La función de <code class="naranja--text">ELIF</code> es agrupar múltiples condiciones, en cambio, 
              <code class="naranja--text">ELSE</code> se utiliza en caso de que ninguna de las opciones anteriores sea verdadera.
            </p>
            <p>
              Se puede añadir tantas condiciones <code class="naranja--text">ELIF</code>  como se requiera, pero solo debe existir un bloque
              <code class="naranja--text">ELSE</code> por cada sentencia <code class="naranja--text">IF</code>. Sintaxis:
            </p>
            <p class="text-center">
              <img src="../../src/assets/examples/elif_else.svg" />
            </p>
            <h3>While</h3>
            <p>
              El ciclo <code class="naranja--text">WHILE</code> <strong>ejecuta de manera continua</strong> el código contenido entre las palabras claves
              <code class="naranja--text">THEN</code> y <code class="naranja--text">END</code> siempre que su condición lógica sea verdadera. Sintaxis:
            </p>
            <p class="text-center">
              <img src="../../src/assets/examples/while.svg" />
            </p>
            <h3>Do while</h3>
            <p>
              Trabaja de manera similar al ciclo <code class="naranja--text">WHILE</code>, con la diferencia de que siempre <strong>ejecuta una vez</strong>
              el código contenido entre las palabras claves y <strong>luego valida</strong> la condición lógica. Sintaxis:
            </p>
            <p class="text-center">
              <img src="../../src/assets/examples/do_while.svg" />
            </p>
            <h3>For</h3>
            <p>
              El ciclo <code class="naranja--text">FOR</code> se utiliza para ejecutar <strong><span class="font-italic">n</span> cantidad de veces</strong> el código
              contenido entre las palabras claves <code class="naranja--text">THEN</code> y <code class="naranja--text">END</code>. Sintaxis:
            </p>
            <p class="text-center">
              <img src="../../src/assets/examples/for.svg" />
            </p>
            <a href="#funciones-y-metodos" class="index" @click="$vuetify.goTo('#funciones-y-metodos')">
              <h2 id="funciones-y-metodos" class="pt-2"><v-icon left class="pb-2" color="indigo"> fas fa-calculator</v-icon>Funciones y métodos</h2>
            </a>
            <p>
              Una <strong>función</strong> permite escribir una porción de código reutilizable en todo el programa y un
              <strong>método</strong> es una función que devuelve un valor en base a una lógica o cálculo definido.
            </p>
            <p>
              Tanto los métodos como las funciones pueden recibir <strong>variables de entrada</strong>  mientras que en los métodos se debe especificar
              el tipo de dato del valor de la salida. Sintaxis:
            </p>
            <p class="text-center">
              <img
                :style="$vuetify.breakpoint.xs ? 'width: 330px' : ''"
                src="../../src/assets/examples/function.svg"
                />
            </p>
            <p class="text-center">
              <img
                :style="$vuetify.breakpoint.xs ? 'width: 300px' : ''"
                src="../../src/assets/examples/method.svg"
                />
            </p>
            <p>
              Para llamar a un método o función se debe utilizar la palabra <code>CALL</code> e indicar las variables de entrada (en caso de que existan)
              con <code>WITH</code>. Es recomendable asignar los métodos a una variable del mismo tipo de dato que su salida. Sintaxis:
            </p>
            <p class="text-center">
              <code><span class="naranja--text">CALL</span> Funcion <span class="naranja--text">WITH</span> variable_1, variable_2</code>
            </p>
            <p class="text-center mb-6">
              <code>
                <span class="naranja--text">INT</span> resultado = <span class="naranja--text">CALL</span> Metodo
                <span class="naranja--text">WITH</span> variable_1
              </code>
            </p>
            <a href="#estructura-del-lenguaje" class="index" @click="$vuetify.goTo('#estructura-del-lenguaje')">
              <h2 id="estructura-del-lenguaje" class="pt-2"><v-icon left class="pb-2" color="pink"> fas fa-stream</v-icon>Estructura del lenguaje</h2>
            </a>
            <p>
              Para el funcionamiento óptimo de Rosy se debe mantener un orden en el código y, además, respetar una estrucura en el lenguaje, la cual
              establece que lo primero a declarar son las <strong>varibales globales</strong>, luego todas las <strong>funciones y/o métodos</strong>
              y por último la <strong>lógica del programa</strong>.
            </p>
            <p class="text-center">
              <img
                :style="$vuetify.breakpoint.xs ? 'width: 300px' : ''"
                src="../../src/assets/examples/estructura.svg"
                />
            </p>
            <a href="#funciones-generales" class="index" @click="$vuetify.goTo('#funciones-generales')">
              <h2 id="funciones-generales" class="pt-2"><v-icon left class="pb-2" color="blue-grey"> fas fa-cogs</v-icon>Funciones generales</h2>
            </a>
            <p>
              El lenguaje cuenta con las siguientes funciones matemáticas predeterminadas:
            </p>
            <h3>Abs</h3>
            <p>
              Calcula el valor absoluto de un número. Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">ABS</span> numero</code>
            </p>
            <h3>Max</h3>
            <p>
              Calcula el mayor de dos números. Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">MAX</span> numero_a, numero_b</code>
            </p>
            <h3>Min</h3>
            <p>
              Calcula el menor de dos números. Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">MIN</span> numero_a, numero_b</code>
            </p>
            <h3>Sqrt</h3>
            <p>
              Calcula la raíz cuadrada de un número. Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">SQRT</span> numero</code>
            </p>
            <h3>Map</h3>
            <p>
              Mapea un número de un rango a otro, es decir, el valor de <code>valor_minimo</code> se asignará a <code>nuevo_valor_minimo</code>
              y el valor de <code>valor_maximo</code> a <code>nuevo_valor_maximo</code>, los valores internos del rango se calculan de manera similar. Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>
                <span class="naranja--text">MAP</span> numero <span class="naranja--text">FROM</span> valor_minimo, valor_maximo
                <span class="naranja--text">TO</span> nuevo_valor_minimo, nuevo_valor_maximo
              </code>
            </p>
            <h3>Cos</h3>
            <p>
              Calcula el coseno de un número. Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">COS</span> numero</code>
            </p>
            <h3>Sin</h3>
            <p>
              Calcula el seno de un número. Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">SIN</span> numero</code>
            </p>
            <h3>Tan</h3>
            <p>
              Calcula la tangente de un número. Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">TAN</span> numero</code>
            </p>
            <h3>Random</h3>
            <p>
              Genera un número pseudo-alearorio comprendido entre los valores <code>minimo_entero</code> y <code>maximo_entero</code>. Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">RANDOM IN RANGE</span> minimo_entero, maximo_entero</code>
            </p>
            <p>
              El parámetro <code>minimo_entero</code> es opcional, en caso de omitirlo éste toma el valor de <code>0</code> y la sintaxis se reduce a:
            </p>
            <p class="text-center mb-6">
              <code><span class="naranja--text">RANDOM</span> maximo_entero</code>
            </p>
            <a href="#funciones-de-arduino" class="index" @click="$vuetify.goTo('#funciones-de-arduino')">
              <h2 id="funciones-de-arduino" class="pt-2"><v-icon left class="pb-2" color="cyan"> fas fa-robot</v-icon>Funciones de Arduino</h2>
            </a>
            <p>
              Rosy cuenta con las siguientes funciones exclusivas para Arduino:
            </p>
            <h3>High / Low</h3>
            <p>
              Se utiliza para establecer el valor de un pin digital. En el caso de <code>HIGH</code> el valor será de 5V (en la placa Arduino UNO) y
              en <code>LOW</code> el valor es de 0V. <a href="/documentacion/ejemplos#blink">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center">
              <code>nombre_pin.<span class="azul--text">HIGH</span></code>
            </p>
            <p class="text-center mb-6">
              <code>nombre_pin.<span class="azul--text">LOW</span></code>
            </p>
            <h3>Write</h3>
            <p>
              Se utiliza para establecer el valor de un pin analógico o pwm (~). <a href="/documentacion/ejemplos#write">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>nombre_pin.<span class="azul--text">WRITE</span> valor</code>
            </p>
            <h3>Read</h3>
            <p>
              Devuelve el valor actual de un pin analógico, digital o pwm (~). <a href="/documentacion/ejemplos#read">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>nombre_pin.<span class="azul--text">READ</span></code>
            </p>
            <h3>Tone / No tone</h3>
            <p>
              <code>TONE</code> genera una onda cuadrada de una frecuencia definida (con un duty cycle de 0.5). Se puede especificar
              la duración de la onda (opcional) pero si no se la establece, la onda continúa hasta que se llame a <code>NO TONE</code>.
              <a href="/documentacion/ejemplos#tone-no-tone">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center">
              <code>nombre_pin.<span class="azul--text">TONE</span> frecuencia, tiempo</code>
            </p>
            <p class="text-center mb-6">
              <code>nombre_pin.<span class="azul--text">NO TONE</span></code>
            </p>
            <h3>Pulse</h3>
            <p>
              Se utiliza para obtener el tiempo que tarda un pin digital en pasar de <code>LOW</code> a <code>HIGH</code> o viceversa, es decir, para leer
              el pulso de un pin. Se puede definir un tiempo de espera (opcional) y, en caso que el pulso supere el tiempo de espera, esta función devuelve 0.
              <a href="/documentacion/ejemplos#pulse">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>nombre_pin.<span class="azul--text">PULSE</span> valor, tiempo</code>
            </p>
            <a href="#funciones-especificas" class="index" @click="$vuetify.goTo('#funciones-especificas')">
              <h2 id="funciones-especificas" class="pt-2"><v-icon left class="pb-2" color="light-green"> fas fa-sliders-h</v-icon>Funciones específicas</h2>
            </a>
            <p>
              La mayoría de los <a @click="$vuetify.goTo('#sensores-y-actuadores')">sensores y actuadores</a>
              disponibles en Rosy cuentan con funciones específicas que facilitan su manipulación:
            </p>
            <h3>DISTANCE</h3>
            <p>
              Obtiene la distancia (en centímetros) que existe entre el <strong>sensor ultrasónico</strong> y un objeto situado a la misma altura.
              <a href="/documentacion/ejemplos#sensor-ultrasonico">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>ultra.<span class="azul--text">DISTANCE</span></code>
            </p>
            <h3>X_AXIS</h3>
            <p>
              Devuelve la posición en el eje x del stick analógico de un <strong>módulo joystick</strong>.
              <a href="/documentacion/ejemplos#modulo-joystick">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>joystick.<span class="azul--text">X_AXIS</span></code>
            </p>
            <h3>Y_AXIS</h3>
            <p>
              Devuelve la posición en el eje y del stick analógico de un <strong>módulo joystick</strong>.
              <a href="/documentacion/ejemplos#modulo-joystick">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>joystick.<span class="azul--text">Y_AXIS</span></code>
            </p>
            <h3>PUSH</h3>
            <p>
              Detecta cuando se presiona el stick analógico de un <strong>módulo joystick</strong>.
              <a href="/documentacion/ejemplos#modulo-joystick">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>joystick.<span class="azul--text">PUSH</span></code>
            </p>
            <h3>ANGLE</h3>
            <p>
              Obtiene el ángulo en el que se encuentra posicionado el eje de un <strong>servomotor</strong>.
              <a href="/documentacion/ejemplos#servomotor">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>servo.<span class="azul--text">ANGLE</span></code>
            </p>
            <h3>ROTATE</h3>
            <p>
              Se utiliza para rotar el eje de un <strong>servomotor</strong> al <code>angulo</code> indicado (entre 0 y 180).
              <a href="/documentacion/ejemplos#servomotor">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>servo.<span class="azul--text">ROTATE</span> angulo</code>
            </p>
            <h3>SOUNDING</h3>
            <p>
              Esta función permite que el eje de un <strong>servomotor</strong> rote simulando un sondeo entre un <code>angulo_min</code>
              y un <code>angulo_max</code>. Es necesario indicar la <code>velocidad</code> de rotacíon del eje. El atributo
              <code>SOFT</code> se utiliza para realizar un giro suave de regreso al ángulo mínimo cuando el eje alcanza el ángulo máximo,
              si se omite, el giro será más brusco.
              <a href="/documentacion/ejemplos#servomotor">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>servo.<span class="azul--text">SOUNDING</span> angulo_min, angulo_max, velocidad, <span class="naranja--text">SOFT</span></code>
            </p>
            <h3>SCOREBOARD</h3>
            <p>
              Se utiliza en el <strong>módulo display 4 dígitos</strong> como tablero de puntajes. Si uno de los números ingresados es mayor a 9 se deberá
              especificar el atributo <code>PLUS</code>, el cual permite ingresar valores de 0 hasta 99.
              <a href="/documentacion/ejemplos#modulo-display-4-digitos">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center">
              <code>display4d.<span class="azul--text">SCOREBOARD</span> numero, numero</code>
            </p>
            <p class="text-center mb-6">
              <code>display4d.<span class="azul--text">SCOREBOARD</span> <span class="naranja--text">PLUS</span> numero, numero</code>
            </p>
            <h3>NUMBER</h3>
            <p>
              Muestra un número ingresado (de 0 hasta 9999) en el <strong>módulo display 4 dígitos</strong>.
              <a href="/documentacion/ejemplos#modulo-display-4-digitos">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>display4d.<span class="azul--text">NUMBER</span> numero</code>
            </p>
            <h3>DOT</h3>
            <p>
              Enciende un punto en una <strong>matriz LED 8x8</strong> situado en la posición indicada mediante <code>fila</code> y <code>columna</code>.
              Se debe indicar la matriz en la que se mostrará el punto mediante <code>num_matriz</code>.
              <a href="/documentacion/ejemplos#matriz-led-8x8">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>matriz.<span class="azul--text">DOT</span> fila, columna, <span class="naranja--text">IN</span> num_matriz</code>
            </p>
            <h3>COLUMN</h3>
            <p>
              Se utiliza en una <strong>matriz LED 8x8</strong> para especificar los puntos de una columna que se iluminan mediante un código binario de la
              forma <code>BXXXXXXXX</code> dónde <code>X</code> puede ser un <code>0</code> o un <code>1</code> y éstos establecen si el led se prende (1) o se apaga (0).
              Se debe indicar la matriz en la que se mostrará el punto mediante <code>num_matriz</code>.
              <a href="/documentacion/ejemplos#matriz-led-8x8">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>matriz.<span class="azul--text">COLUMN</span> columna, binario, <span class="naranja--text">IN</span> num_matriz</code>
            </p>
            <h3>ROW</h3>
            <p>
              Se utiliza en una <strong>matriz LED 8x8</strong> para especificar los puntos de una fila que se iluminan mediante un código binario de la
              forma <code>BXXXXXXXX</code> dónde <code>X</code> puede ser un <code>0</code> o un <code>1</code> y éstos establecen si el led se prende (1) o se apaga (0).
              Se debe indicar la matriz en la que se mostrará el punto mediante <code>num_matriz</code>.
              <a href="/documentacion/ejemplos#matriz-led-8x8">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>matriz.<span class="azul--text">ROW</span> fila, binario, <span class="naranja--text">IN</span> num_matriz</code>
            </p>
            <h3>TEXT</h3>
            <p>
              Muestra un <code>texto</code> ingresado <code>n</code> cantidad de veces en una <strong>matriz LED 8x8</strong>.
              Se debe indicar la cantidad de marices conectadas a la placa.
              <a href="/documentacion/ejemplos#matriz-led-8x8">Ver ejemplo.</a> Sintaxis:
            </p>
            <p class="text-center mb-6">
              <code>matriz.<span class="azul--text">TEXT</span> texto, cantidad_matrices, n </code>
            </p>
            <h3>CLEAR</h3>
            <p>
              Se utiliza para limpiar los valores de un <strong>módulo display 4 dígitos</strong>. En el caso de una <strong>matriz LED 8x8</strong> se debe
              indicar la matriz que se quiere limpiar mediante <code>num_matriz</code>. Si no se especifica el número de la misma y se utiliza el atributo
              <code>ALL</code>, se limpiarán todas las matrices conectadas a la placa. Sintaxis:
            </p>
            <p class="text-center">
              <code>display4d.<span class="azul--text">CLEAR</span></code>
            </p>
            <p class="text-center">
              <code>matriz.<span class="azul--text">CLEAR</span> num_matriz</code>
            </p>
            <p class="text-center mb-6">
              <code>matriz.<span class="azul--text">CLEAR</span> <span class="naranja--text">ALL</span></code>
            </p>
            <div class="d-flex mt-8">
              <v-btn
                outlined
                color="info"
                class="body-1"
                style="text-transform: none"
                to="/documentacion"
                :large="$vuetify.breakpoint.smAndDown"
              >
                <v-icon left>fas fa-arrow-left</v-icon>
                Anterior: <br v-if="$vuetify.breakpoint.smAndDown">Empezar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="info"
                class="body-1 text-right"
                style="text-transform: none"
                to="/documentacion/ejemplos"
                :large="$vuetify.breakpoint.smAndDown"
              >
                Siguiente:<br v-if="$vuetify.breakpoint.smAndDown"> Ejemplos
                <v-icon right>fas fa-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Index from '../components/Index'
import Componente from '../components/Component'
import Tooltip from '../components/Tooltip'

export default {
  title: 'Documentación',
  data: () => ({
    items: [
      {
        large: false,
        img: require('../assets/res/button.png'),
        title: 'Botón',
        sintax: '<span class="naranja--text">PIN</span> pin_digital, nombre_variable, <span class="naranja--text">INP_PUL</span>'
      },
      {
        large: false,
        img: require('../assets/res/cny70.png'),
        title: 'CNY70 (Sensor de color)',
        sintax: '<span class="naranja--text">PIN</span> pin_analogico, nombre_variable'
      },
      {
        large: false,
        img: require('../assets/res/lm35.png'),
        title: 'LM35 (Sensor de temperatura)',
        sintax: '<span class="naranja--text">PIN</span> pin_analogico, nombre_variable'
      },
      {
        large: false,
        img: require('../assets/res/ldr.png'),
        title: 'LDR (Sensor de luz)',
        sintax: '<span class="naranja--text">PIN</span> pin_analogico, nombre_variable'
      },
      {
        large: false,
        img: require('../assets/res/led.png'),
        title: 'LED',
        sintax: '<span class="naranja--text">PIN</span> pin_digital, nombre_variable, <span class="naranja--text">OUT</span>'
      },
      {
        large: false,
        img: require('../assets/res/buzzer.png'),
        title: 'Buzzer',
        sintax: '<span class="naranja--text">PIN</span> pin_digital, nombre_variable, <span class="naranja--text">OUT</span>'
      },
      {
        large: true,
        img: require('../assets/res/servo.png'),
        title: 'Servomotor SG90',
        sintax: '<span class="naranja--text">SERVO</span> pin_pwm, nombre_variable'
      },
      {
        large: true,
        img: require('../assets/res/ultra.png'),
        title: 'HC-SR04 (Sensor ultrasónico)',
        sintax: '<span class="naranja--text">ULTRASONIC</span> trig, echo, nombre_variable'
      },
      {
        large: true,
        img: require('../assets/res/puenteh.png'),
        title: 'Puente H',
        sintax: '<span class="naranja--text">PIN</span> pin_pwm, in1<br/><span class="naranja--text">PIN</span> pin_pwm, in2<br/><span class="naranja--text">PIN</span> pin_pwm, in3<br/><span class="naranja--text">PIN</span> pin_pwm, in4'
      },
      {
        large: true,
        img: require('../assets/res/display4d.png'),
        title: 'Módulo display 4 dígitos',
        sintax: '<span class="naranja--text">DISPLAY</span> clk, dio, nombre_variable'
      },
      {
        large: true,
        img: require('../assets/res/joystick.png'),
        title: 'Módulo joystick',
        sintax: '<span class="naranja--text">JOYSTICK</span> vrx, vry, sw, nombre_variable'
      },
      {
        large: true,
        img: require('../assets/res/matrix.png'),
        title: 'Módulo matriz LED 8x8',
        sintax: '<span class="naranja--text">MATRIX</span> cantidad_matrices, din, clk, cs, nombre_variable'
      },
    ],
  }),
  mounted() {
    if (this.$route.hash) this.$vuetify.goTo(this.$route.hash)
    else this.$vuetify.goTo(0)
  },
  components: {
    Index, Componente, Tooltip
  },
}
</script>